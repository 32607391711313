<template>
    <div>
        <div class="content-header clearfix">
            <h1 class="float-left">
                {{ $t('pages.account.deposit') }}
                <small>
                    <slot name="back">
                        <CIcon name="cil-arrow-circle-left"></CIcon>
                        <a href="javascript:void(0)" @click="onCancel">{{ $t('common.back') }}</a>
                    </slot>
                </small>
            </h1>
        </div>
        <CRow>
            <CCol sm="12">
                <div>
                    <CCard>                        
                        <CCardBody>
                            <CForm>
                                <CInput :placeholder="$t('pages.bank.enterAccountName')" v-model="accountName" required horizontal :is-valid="validator">
                                    <template #label>
                                        <div class="col-sm-3 col-form-label">
                                            {{ $t('pages.bank.accountName') }}
                                            <small class="requiredInput">(*)</small>
                                        </div>
                                    </template>
                                </CInput>
                                <CInput :placeholder="$t('pages.bank.enteraccountNo')" v-model="accountNo" required horizontal :is-valid="validator">
                                    <template #label>
                                        <div class="col-sm-3 col-form-label">
                                            {{ $t('pages.bank.accountNo') }}
                                            <small class="requiredInput">(*)</small>
                                        </div>
                                    </template>
                                </CInput>

                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">
                                        {{ $t('pages.bank.bank') }}
                                        <small class="requiredInput">(*)</small>
                                    </label>
                                    <div class="col-sm-9 input-group">
                                        <select class="form-control" v-model="bankAccountId">
                                            <option v-for="(item) in bankList" v-bind:key="item.id" v-bind:value="item.id">
                                                {{ item.bankName + " - " + item.bankCode }}
                                            </option>
                                        </select>
                                    </div>
                                </div>                                
                                <CInput :placeholder="$t('pages.bank.enterBranch')" v-model="bankBranch" required horizontal>
                                    <template #label>
                                        <div class="col-sm-3 col-form-label">
                                            {{ $t('pages.bank.branch') }}
                                        </div>
                                    </template>
                                </CInput>

                                <div class="row">
                                    <CButton type="button" color="primary" @click="onAddBankAccount">{{ this.$t('common.save') }}</CButton>
                                </div>
                            </CForm>
                        </CCardBody>
                    </CCard>
                    <Confirmation ref="confirmation"></Confirmation>
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    
    export default {
        name: 'AddBankAccount',
		data() {            
			return {                
                horizontal: { label: 'col-3', input: 'col-9' },               
                accountName: '',
                accountNo: '',
                bankAccountId: 0,
                bankBranch: ''
            };
		},
        components: {           
            Confirmation,          
        },
        computed: {                      
            ...mapGetters('bank', ['isLoadingBanks', 'bankList']),           
        },
        methods: {           
            validator(val) {
                return val ? val.length > 0 : false
            },
            onCancel() {
                this.$router.push('/users/profile');
            },
            async onAddBankAccount() {

                if (this.accountName === '') {
                    this.$store.dispatch('alert/error', this.$t('pages.bank.enterAccountName'), { root: true });
                    return;
                }
                if (this.accountNo === '') {                   
                    this.$store.dispatch('alert/error', this.$t('pages.bank.enteraccountNo'), { root: true });
                    return;
                }

                if (this.bankAccountId <= 0) {

                    this.$store.dispatch('alert/error', this.$t('pages.bank.noticeSelectBank'), { root: true });
                    return;
                }
                
                var retVal = await this.$store.dispatch("profile/addBankAcountForUser", {
                    accountName: this.accountName,
                    accountNo: this.accountNo,
                    branch: this.bankBranch,
                    bankId: this.bankAccountId
                });

              
                
                if (retVal.success == true) {
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.bank.noticeAddBankAccountSuccess') });

                    this.$router.push('/users/profile');
                }
                else {
                    let message = i18n.t('pages.bank.noticeAddBankAccountFailed');
                    //if (retVal.errorMessage != '') {
                    //    switch (retVal.errorMessage) {
                    //        case 'Invalid AccountNo': i18n.t('pages.bank.accountNo'); break;
                    //        case 'Invalid PayerName': i18n.t('pages.deposit.enterpayerName'); break;
                    //        case 'Invalid File': i18n.t('pages.deposit.uploadProofOfBankTransactions'); break;
                    //        case 'Invalid Amount': i18n.t('pages.deposit.entertheamount'); break;
                    //    }
                    //}
                    this.$store.dispatch('alert/error', message, { root: true });
                }
            },            
		},
        mounted() {
            this.$store.dispatch("bank/getAllBanks");
        }
	}
</script>
